import React from "react";
import { useGlobalContext } from "./context/globalContext";

const AboutDeveloper = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section className="section shadow-sm lazyloaded" id="developer">
      <div className="d-block pt-2 pb-1 text-center">
        <img
          src="images/bhandup/lntevaraheightslogo.webp"
          width="150px"
          height="70px"
          loading="lazy"
          alt="Lnt Evara Heights Logo, L&T Evara Heights Thane Logo"
        />
      </div>
      <div className="row">
        <div className="col-md-8">
          <h2 className="d-block section-heading-sub text-capitalize">
            About Developer
          </h2>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale download_brochure float-lg-right d-none d-lg-block"
            onClick={handleShowModal}
          >
            Chat with us
          </button>
        </div>
      </div>

      <p>
        Established in 2011, L&T Realty is the real estate arm of the $23
        billion Larsen and Toubro and a trendsetter amidst real estate
        developers in India. . L&T Realty is committed to creating landmarks of
        excellence and providing customer delight at every touch point through
        design innovation and operational excellence. The business has built a
        reputation of standing by its promises and embracing the power of
        digitization and new technologies into its core strategy for growth.
      </p>

      <span className="pro-rera text-center-mob">
        L&T Evara Heights Thane has been registered via MahaRERA registration
        number and is available on the website{" "}
        <a href="https://maharera.mahaonline.gov.in/">
          https://maharera.mahaonline.gov.in/
        </a>{" "}
        under registered projects.
      </span>

      <p></p>
      <button
        className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale download_brochure float-lg-right mx-sm-auto d-lg-none"
        data-form="lg"
        data-title=" Chat with us"
        data-btn=" Chat with us"
        data-enquiry="Download Brochure Left Panel"
        data-redirect="enquiry"
        data-toggle="modal"
        data-target="#enqModal"
      >
        Chat with us
      </button>
    </section>
  );
};

export default AboutDeveloper;
