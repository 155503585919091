import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Details = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section className="section shadow-sm lazyloaded" id="overview">
      <h2 className="d-block section-heading color-primary text-capitalize">
        L&T Evara Heights Thane
      </h2>
      <h2 className="head text-capitalize">
        L&T Realty New Launch Next To Viviana Mall | 2 , 2.5 , 3 & 4 BHK In
        Thane{" "}
      </h2>
      <p>
        L&T Evara Heights redefines opulent living with its upscale residences,
        exceptional amenities, and breath-taking architectural finesse. Nestled
        within one of Thane’s most sought-after neighbourhoods Next to Viviana
        Mall Step into the refined world of L&T Evara Heights, this project
        harmonizes urban accessibility with a tranquil retreat, rendering it a
        prized address for those with a discerning eye for elegance.
        <span id="text">
          L&T Evara Heights Residences exhibit an impeccable blend of spacious
          layouts and sophisticated interiors, embodying a refined aura.
          Offering meticulously crafted 2, 2.5, 3, and 4 BHK apartments, each
          residences is thoughtfully designed to capture an abundance of natural
          light, seamless ventilation, and utmost comfort. Premium finishes each
          home with an aura of luxury, setting a benchmark for exclusivity.
          {/* Whether you’re in search of a cozy abode or a lavish expanse, L&T
          Evara Heights offers a choice that aligns seamlessly with your
          lifestyle aspirations.L&T Evara Heights boasts an array of
          unparalleled amenities, catering to the multifaceted needs of its
          residents. From a state-of-the-art fitness centre, an inviting
          swimming pool, and exquisitely landscaped gardens to designated play
          zones and wellness retreats, L&T Evara Heights Thane curates an
          environment for relaxation and rejuvenation. Ensuring 24/7 security
          coupled with concierge services, residents experience a lifestyle
          steeped in ease and tranquility.Strategically poised in Thane Next To
          Viviana Mall, the project provides seamless connectivity to Mumbai’s
          key locales, from bustling business districts to premier shopping and
          entertainment hubs. The proximity to educational institutions,
          healthcare facilities, and transit links further amplifies its appeal,
          positioning L&T Evara Heights as the ultimate fusion of luxury,
          convenience, and lifestyle. Apartments are available in diverse
          configurations, with open layouts, expansive windows to invite natural
          light, and premium finishes accentuating the essence of luxury at each
          turn. Every inch exudes meticulous attention to detail, from the
          expansive living areas to the sophisticated bedrooms and
          culinary-centric kitchens.L&T Evara Heights Thane is also committed to
          sustainability, integrating eco-conscious practices in both
          construction and design. Features like energy-saving lighting,
          rainwater harvesting, and thoughtfully structured ventilation systems
          not only minimize the environmental impact but provide residents with
          a modern, sustainable living experience. Green spaces, including lush
          gardens and open recreational areas, contribute not only to aesthetic
          appeal but also foster a healthier, more balanced lifestyle.With a
          strategic placement in Thane, L&T Evara Heights affords swift
          connectivity to essential Mumbai locales while cultivating a serene
          residential atmosphere—ideal for those seeking a blend of luxury and
          tranquility. Step into the refined world of L&T Evara Heights Thane,
          where each element is meticulously designed to elevate your lifestyle,
          providing unmatched sophistication and comfort. */}
        </span>
      </p>
      <button id="toggle" className="btn btn-link btn-sm more">
        Read More
      </button>

      <button
        className="chat-btn btn btn-info micro-form-btn effetMoveGradient enqModal download_brochure"
        data-form="md"
        data-title="Download Brochure"
        data-btn="Download Now"
        data-enquiry="Download Brochure Welcome Text"
        data-redirect="brochure"
        data-redirect-file="brochure.pdf"
        data-toggle="modal"
        data-target="#enqModal"
        onClick={handleShowModal}
      >
        <span className="d-inline-block mi mi-download mr-1 animated slideInDown infinite"></span>{" "}
        Download Brochure
      </button>

      <center>
        <h2 className="mt-3">
          <b>L&T Evara Heights Highlights</b>
        </h2>
      </center>

      <div
        className="mr0 aos-init aos-animate mt-2"
        data-aos="fade-up"
        data-aos-delay="600"
        data-aos-duration="3000"
      >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <ul className="whybuy">
              <li>
                <span>Located in the centre of Thane</span>
              </li>
              <li>
                <span>Excellent connectivity to Mumbai</span>
              </li>
              <li>
                <span>Close to the Viviana and Korum Malls</span>
              </li>
              <li>
                <span>Close to recreational spaces like Upvan Lake</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <ul className="whybuy">
              <li>
                <span>Short distance from spas and wellness centres.</span>
              </li>
              <li>
                <span>Modern Infrastructure</span>
              </li>
              <li>
                <span>Contemporary and elegant apartments</span>
              </li>
              <li>
                <span>Premium amenities</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
