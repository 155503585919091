import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Amenities = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section className="section shadow-sm lazyloaded" id="amenities">
      <span className="section-link"></span>{" "}
      <div className="row">
        <div className="col-md-8">
          <h2 className="head text-capitalize">
            Amenities of L&T Evara Heights Thane
          </h2>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale float-lg-right mx-sm-auto  d-none d-lg-block"
            onClick={handleShowModal}
          >
            Download Amenities
          </button>
        </div>
      </div>
      <div className="owl-carousel-fp owl-carousel owl-theme ticket-amenities">
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsclubhouse.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsindoorgameroom.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightslandscapedgreens.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightskidsplayarea.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsmultipurposesportscourt.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheights247security.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsamphitheatre.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsgym.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsjoggingtrack.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsyoga.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
        <div
          className="item"
          data-form="lg"
          data-title="Download Floor Plan"
          data-btn="Submit"
          data-enquiry="Floor Plan Footer tab mobile"
          data-redirect="enquiry"
          data-toggle="modal"
          data-target="#enqModal"
        >
          <img
            src="images/bhandup/lntevaraheightsswimmingpool.webp"
            alt="Lnt Evara Heights Amenities, L&T Evara Heights Thane Amenities"
          />
        </div>
      </div>
      <div className="col-12">
        <p></p>
        <button
          className="chat-btn btn btn-info micro-form-btn enqModal effetMoveGradient effectScale float-lg-right mx-sm-auto d-lg-none"
          data-form="lg"
          data-title="Download Amenities"
          data-btn="Send Now"
          data-enquiry="Download Amenities"
          data-redirect="floorplan"
          data-toggle="modal"
          data-target="#enqModal"
          onClick={handleShowModal}
        >
          Download Amenities
        </button>
      </div>
    </section>
  );
};

export default Amenities;
