import React from "react";

import { useGlobalContext } from "./context/globalContext";

const Hero = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <div id="home" className="section">
      <div className="desktopview">
        <img
          onClick={handleShowModal}
          height="730"
          width="100%"
          className="d-block micro-main-slider-img ls-is-cached lazyloaded"
          src="images/bhandup/lntevaraheightsweb2.jpg"
          alt="L&T Evara Heights Thane Web Banner, L&T Evara Heights Web Banner"
        />
      </div>
      <div className="mobileview">
        <img
          onClick={handleShowModal}
          height="100%"
          width="100%"
          className="d-block micro-main-slider-img ls-is-cached lazyloaded"
          src="images/bhandup/lntevaraheightsmob2.jpg"
          alt="L&T Evara Heights Thane Mobile Banner, L&T Evara Heights Mobile Banner"
        />
      </div>
    </div>
  );
};

export default Hero;
